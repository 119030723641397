import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import Img from "gatsby-image/withIEPolyfill"

import Header from "./header"
import PlayButton from "./play-button"
import VideoInline from "./video-inline"
import MediaModalCarousel from "./media-modal-carousel"
import CallToActions from "./call-to-actions"

const Container = styled.div`
  height: 100vh;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20%;
    background-image: linear-gradient(
      180deg,
      rgba(10, 10, 10, 0.8) 0%,
      rgba(10, 10, 10, 0) 100%
    );
    z-index: 0;
  }
`

const Inner = styled.section`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Play = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${props => props.theme.xlargeDown} {
    font-size: ${rem(12)};
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > div {
    max-width: ${rem(600)};
  }
`

const ContentHeading = styled.h2`
  ${props => props.theme.ffSecondarySet()}

  margin-bottom: ${rem(10)};

  span {
    margin-bottom: ${rem(10)};
    display: block;
    color: ${props => props.theme.colorYellow};
  }
`

const HeaderWrap = styled.div`
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
`

const WarSupportWrap = styled.div`
  width: 100%;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
`

const BackgroundMedia = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  > * {
    width: 100%;
    height: 100%;
  }
`

const HeroCta = ({ data, displayHeader }) => {
  if (!data.published) return null

  const [displayModal, setDisplayModal] = useState(false)

  const modalData = [
    {
      type: `video`,
      videoUrl: data.videoUrl,
    },
  ]

  const playClick = e => {
    e.preventDefault()
    setDisplayModal(true)
  }

  return (
    <Container id={data.htmlId}>
      {displayHeader && (
        <HeaderWrap>
          <Header />
        </HeaderWrap>
      )}
      {displayHeader && <WarSupportWrap></WarSupportWrap>}

      <Inner>
        {data.type === `video` && data.videoUrl && (
          <Play>
            <PlayButton
              to={data.videoUrl}
              title="Play video"
              aria-label="Play video"
              data-track-click
              data-track-click-ga-category="Hero CTA"
              data-track-click-ga-action="Play video"
              data-track-click-ga-label={data.htmlId}
              onClick={playClick}
            />
          </Play>
        )}

        {data.type === `content` && (
          <Content>
            <div>
              <ContentHeading>
                {data.label && (
                  <span
                    dangerouslySetInnerHTML={{ __html: data.label }}
                    className="styled-h3"
                  />
                )}

                {data.title && (
                  <b
                    dangerouslySetInnerHTML={{ __html: data.title }}
                    className="styled-h1-large"
                  />
                )}
              </ContentHeading>

              <CallToActions cta={data.cta} />
            </div>
          </Content>
        )}
      </Inner>

      {data.backgroundMedia === `image` &&
        data.backgroundImage &&
        data.backgroundImage.localFile && (
          <BackgroundMedia>
            <Img
              fluid={data.backgroundImage.localFile.childImageSharp.fluid}
              alt=""
              role="presentation"
            />
          </BackgroundMedia>
        )}

      {data.backgroundMedia === `video` && data.backgroundVideoUrl && (
        <BackgroundMedia>
          <VideoInline url={data.backgroundVideoUrl} />

          {data.backgroundImage && data.backgroundImage.localFile && (
            <Img
              fluid={data.backgroundImage.localFile.childImageSharp.fluid}
              alt=""
              role="presentation"
            />
          )}
        </BackgroundMedia>
      )}

      {displayModal && (
        <MediaModalCarousel
          data={modalData}
          hideCurrentSlideIndex={true}
          onRequestClose={() => setDisplayModal(false)}
        />
      )}
    </Container>
  )
}

HeroCta.propTypes = {
  data: PropTypes.object.isRequired,
  displayHeader: PropTypes.bool,
}

export default HeroCta
