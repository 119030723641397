import React, { useState } from "react"
import PropTypes from "prop-types"
import { useWindowWidth } from "@react-hook/window-size/throttled"
import styled from "styled-components"
import { rem } from "polished"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"

import Character from "./character"
import CallToActions from "../call-to-actions"
import SectionHeader from "../section-header"
import { breakpoints } from "../../theme/sections/mq"
import { ReactComponent as SvgChevronRight } from "../../assets/images/icons/green-arrow.svg"

const Container = styled.section`
  background: ${props => props.theme.colorGreyDarkest};
  position: relative;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }

  &::before {
    background-size: 6px 6px;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNCAxNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTQgMTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMjYyNjI2O30KPC9zdHlsZT4KPGc+Cgk8Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSIyLjUiIGN5PSIyLjUiIHI9IjIuNSIvPgo8L2c+CjxnPgoJPGNpcmNsZSBjbGFzcz0ic3QwIiBjeD0iOS41IiBjeT0iOS41IiByPSIyLjUiLz4KPC9nPgo8L3N2Zz4K");
    opacity: 0.4;
  }

  &::after {
    background-image: radial-gradient(
      50% 92%,
      rgba(10, 10, 10, 0) 20%,
      rgba(10, 10, 10, 0.9) 90%
    );
  }
`

const Inner = styled.div`
  padding-bottom: ${rem(20)};
  position: relative;
  z-index: 1;

  @media ${props => props.theme.mediumDown} {
    padding-top: ${rem(10)};
  }

  .carousel__slider-tray-wrapper {
    width: 100%;
    margin-top: ${rem(60)};

    @media ${props => props.theme.mediumDown} {
      margin-left: ${rem(40)};
      margin-right: ${rem(40)};
    }
  }

  .carousel__back-button {
    position: absolute;
    top: 50%;
    left: 0;
    width: ${rem(80)};
    transform: translateY(-50%);

    &:disabled {
      opacity: 0;
    }
  }

  .carousel__next-button {
    position: absolute;
    top: 50%;
    right: 0;
    width: ${rem(80)};
    transform: translateY(-50%) rotate(180deg);
    transition: opacity: 0.25s ease-in-out;

    &:disabled {
      opacity: 0;
    }
  }
`

const Characters = styled(Slider)`
  ${props => props.theme.gridContainer()}
  ${props => props.theme.gridGrid()}

  > li {
    ${props => props.theme.gridCell(3)}

    display: flex;

    @media ${props => props.theme.xxxlarge} {
      ${props => props.theme.gridCell(4)}
    }

    @media ${props => props.theme.large} {
      ${props => props.theme.gridCell(6)}
    }

    @media ${props => props.theme.smallDown} {
      ${props => props.theme.gridCell(12)}
    }
  }
`

const ComingSoonCharacter = styled.article`
  width: 100%;
  height: 60%;
  position: relative;
  padding: ${rem(20)};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 12%;
  transform: skew(-4deg);
  background-color: ${props => props.theme.colorBlack};

  @media ${props => props.theme.mediumDown} {
    background-color: transparent;
  }

  span {
    ${props => props.theme.ffSecondarySet()}

    font-size: ${rem(30)};
    opacity: 0.4;
  }
`

const Floor = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 28%;
  background-image: linear-gradient(
    180deg,
    rgba(10, 10, 10, 0.4) 0%,
    rgba(27, 27, 27, 0) 100%
  );
`

const filterCharacters = (type, characters) => {
  const filteredCharacters = characters.filter(
    ({ node }) => node.acf.type.termId === type
  )
  return filteredCharacters
}

const sortCharacters = characters => {
  return characters.sort(
    (a, b) => a.node.acf.listingWeight - b.node.acf.listingWeight
  )
}

const sortAndFilterCharacters = (type, characters) => {
  return sortCharacters(filterCharacters(type, characters))
}

const CharacterList = ({
  data: {
    published,
    htmlId,
    filterType,
    sectionHeading,
    comingSoonCharacter,
    cta,
  },
  allCharacters: { edges: allCharacters },
}) => {
  const [visibleSlides, setVisibleSlides] = useState(4)
  const winWidth = useWindowWidth({ initialWidth: breakpoints.xxlarge })

  if (!published) return null

  const setTheVisibleSlides = count => {
    if (typeof window === `undefined`) return false
    window.setTimeout(() => setVisibleSlides(count), 50)
    return true
  }

  if (winWidth < breakpoints.small) {
    if (visibleSlides !== 1) setTheVisibleSlides(1)
  } else if (winWidth < breakpoints.large) {
    if (visibleSlides !== 2) setTheVisibleSlides(2)
  } else if (winWidth < breakpoints.xxxlarge) {
    if (visibleSlides !== 3) setTheVisibleSlides(3)
  } else if (winWidth >= breakpoints.xxxlarge) {
    if (visibleSlides !== 4) setTheVisibleSlides(4)
  }

  const characters = filterType
    ? sortAndFilterCharacters(filterType, allCharacters)
    : allCharacters

  return (
    <Container id={htmlId}>
      <SectionHeader title={sectionHeading} headingLevel="2" />

      <Inner>
        {characters.length > 0 && (
          <CarouselProvider
            naturalSlideWidth={245}
            naturalSlideHeight={444}
            visibleSlides={visibleSlides}
            totalSlides={characters.length + 1}
          >
            <Characters>
              {characters.map(({ node: character }, index) => (
                <Slide key={character.id} index={index}>
                  <Character data={character} />
                </Slide>
              ))}

              {comingSoonCharacter && (
                <Slide index={characters.length + 1}>
                  <ComingSoonCharacter>
                    <span>More coming soon…</span>
                  </ComingSoonCharacter>
                </Slide>
              )}
            </Characters>

            {visibleSlides < characters.length + 1 && (
              <React.Fragment>
                <ButtonBack title="Previous slide" aria-label="Previous slide">
                  <span>
                    <SvgChevronRight />
                  </span>
                </ButtonBack>
                <ButtonNext title="Next slide" aria-label="Next slide">
                  <span>
                    <SvgChevronRight />
                  </span>
                </ButtonNext>
              </React.Fragment>
            )}
          </CarouselProvider>
        )}

        <CallToActions cta={cta} textAlign="center" layout="horizontal" />
      </Inner>
      <Floor />
    </Container>
  )
}

CharacterList.propTypes = {
  data: PropTypes.object.isRequired,
  allCharacters: PropTypes.object.isRequired,
}

export default CharacterList
