import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { rem, darken } from "polished"

import Link from "../link"

const Background = styled.div`
  width: 98%;
  height: 60%;
  position: absolute;
  z-index: 1;
  top: 12%;
  left: 0;
  background-color: ${props => props.theme.colorBlack};
  transition: background 0.3s ${props => props.theme.easingInOutCubic},
    transform 0.3s ${props => props.theme.easingInOutCubic},
    height 0.3s ${props => props.theme.easingInOutCubic};
  transform: skew(-4deg);

  @media ${props => props.theme.mediumDown} {
    height: 87%;
    top: 0;
    opacity: 0.5;
  }

  > * {
    width: 100%;
    height: 100%;
  }

  > div {
    opacity: 0.2;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }

  &::before {
    background-size: 6px 6px;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNCAxNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTQgMTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMjYyNjI2O30KPC9zdHlsZT4KPGc+Cgk8Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSIyLjUiIGN5PSIyLjUiIHI9IjIuNSIvPgo8L2c+CjxnPgoJPGNpcmNsZSBjbGFzcz0ic3QwIiBjeD0iOS41IiBjeT0iOS41IiByPSIyLjUiLz4KPC9nPgo8L3N2Zz4K");
    opacity: 0.4;
  }

  &::after {
    background-image: radial-gradient(
      48% 112%,
      rgba(10, 10, 10, 0) 20%,
      rgba(10, 10, 10, 0.8) 100%
    );
    transition: opacity 0.3s ${props => props.theme.easingInOutCubic};
  }
`

const Image = styled.figure`
  margin-bottom: ${rem(20)};
  transition: transform 0.3s ${props => props.theme.easingInOutQuad};
  height: 82%;

  > * {
    width: 100%;
    height: 100%;
  }
`

const LinkStyled = styled(Link)`
  display: block;
  position: relative;
  z-index: 2;
  height: 100%;

  &:hover ${Image} {
    transform: scale(1.05);
  }
`

const Name = styled.h3.attrs({ className: `styled-h3` })`
  margin-bottom: 0;
  display: block;
  color: ${props => props.theme.colorYellow};
`

const Title = styled.p`
  display: block;
  font-size: ${rem(16)};
`

const Container = styled.article`
  height: 100%;
  width: 100%;
  position: relative;
  text-align: center;

  &:hover ${Background} {
    background-color: ${props => darken(0.4, props.theme.colorYellow)};

    @media ${props => props.theme.mediumUp} {
      height: 70%;
      transform: skew(-4deg) translate(-0.7%, -6%);
    }

    &::after {
      opacity: 0;
    }
  }
`

const Character = ({
  data: {
    slug,
    acf: { listingImage, listingBackground, listingTitle, shortName },
  },
}) => {
  return (
    <Container>
      <LinkStyled to={`/${slug}`}>
        <Image>
          {listingImage && listingImage.localFile && (
            <Img
              fluid={listingImage.localFile.childImageSharp.fluid}
              alt={shortName}
              objectFit="contain"
            />
          )}
        </Image>

        {shortName && <Name dangerouslySetInnerHTML={{ __html: shortName }} />}

        {listingTitle && (
          <Title dangerouslySetInnerHTML={{ __html: listingTitle }} />
        )}
      </LinkStyled>

      <Background>
        {listingBackground && listingBackground.localFile && (
          <Img
            fluid={listingBackground.localFile.childImageSharp.fluid}
            role="presentation"
            alt=""
          />
        )}
      </Background>
    </Container>
  )
}

Character.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Character
