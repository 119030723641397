import React from "react"
import ReactPlayer from "react-player"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  overflow: hidden;
  background-color: ${props => props.theme.colorBlack};

  iframe {
    width: 177.77777778vh !important;
    height: 56.25vw !important;
    min-width: 100% !important;
    min-height: 100% !important;
    box-sizing: border-box !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;

    ${props =>
      props.orientation === `portrait` &&
      css`
        width: 56.25vh !important;
        height: 177.77777778vw !important;
      `}
  }
`

class VideoInline extends React.Component {
  render() {
    const { url, ...props } = this.props

    return (
      <Container {...props}>
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          volume={0}
          muted={true}
          playing={true}
          loop={true}
          playsinline
          // config={{
          //   vimeo: {
          //     playerOptions: {
          //       background: 1,
          //     },
          //   },
          // }}
        />
      </Container>
    )
  }
}

VideoInline.propTypes = {
  url: PropTypes.string.isRequired,
}

export default VideoInline
