import React from "react"
import styled from "styled-components"
import { em } from "polished"

import Link from "./link"

const Container = styled.a`
  width: ${em(200)};
  height: ${em(160)};
  display: block;
  filter: drop-shadow(${em(5)} ${em(5)} ${em(2)} rgba(0, 0, 0, 0.5));

  &:hover {
    span {
      background-color: rgba(0, 0, 0, 0.7);

      &::after {
        transform: translate(-50%, -50%) scale(1.2);
      }
    }
  }

  &:active {
    span {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  span {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    background-color: rgba(0, 0, 0, 0.8);
    transform: skew(-12deg);
    transition: background-color 0.2s ${props => props.theme.easingDefault};

    &::after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-top: ${em(35)} solid transparent;
      border-bottom: ${em(35)} solid transparent;
      border-left: ${em(35)} solid ${props => props.theme.colorWhite};
      transition: transform 0.2s ${props => props.theme.easingDefault};
    }
  }
`

const PlayButton = ({ ...rest }) => {
  return (
    <Container
      as={Link}
      target="_blank"
      role="button"
      title="Play video"
      aria-label="Play video"
      {...rest}
    >
      <span />
    </Container>
  )
}

export default PlayButton
